.App {
    text-align: center;
}

nav {
    background-color: #333;
    padding: 10px;
}

nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

nav li {
    display: inline;
    margin-right: 10px;
}

.content {
    margin: 0;
}
