.custom-nav {
    /* background: radial-gradient(circle at 0%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%); */
    /* background: radial-gradient(circle at 0%, rgba(255, 255, 255, 1) 0%, rgba(225, 207, 163, 1) 100%); */
    /* background: radial-gradient(circle at 0%, rgba(255, 255, 255, 1) 40%, rgba(225, 207, 163, 1) 100%); */
    background: radial-gradient(circle at 0%, rgba(255, 255, 255, 1) 35%, rgba(63, 135, 123, 1) 100%);
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.3));
    z-index: 1;
}

.custom-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.custom-a {
    color: #fff;
    text-decoration: none;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

@media (min-width: 900px) {
    .custom-nav {
        display: none;
    }
    .hamburger {
        display: none;
    }
}

.hamburger {
    position: relative;
    width: 30px;
    height: 20px;
    cursor: pointer;
    user-select: none;
    margin-right: 20px;
}

.hamburger .meat {
    border-radius: 2px;
    width: 100%;
    position: absolute;
    height: 3px;
    background: rgba(255, 255, 255, 1);
    /* background: rgba(225, 207, 163, 1); */
    display: block;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.hamburger .meat:first-child {
    top: 0;
}

.hamburger .meat:nth-child(2), .hamburger .meat:nth-child(3) {
    top: 50%;
    transform: translateY(-50%);
}
.hamburger .meat:last-child {
    bottom: 0;
}
.close .meat:first-child, .close .meat:last-child {
    opacity: 0;
}
.close .meat:first-child {
    transform: translateY(20px) scale(0);
}
.close .meat:last-child {
    transform: translateY(-20px) scale(0);
}
.close .meat:nth-child(2) {
    transform: rotate(45deg);
}
.close .meat:nth-child(3) {
    transform: rotate(-45deg);
}
.menu {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.6s ease-in-out;
}
.menu.active {
    max-height: 500px;
}
.menu li {
    font-weight: 500;
    cursor: pointer;
    position: relative;
}
.menu li a:hover {
    background: radial-gradient(circle at 0%, rgba(255, 255, 255, 1) 35%, rgba(63, 135, 123, 1) 100%);
}
.menu li a, .menu li span {
    color: rgba(255, 255, 255, 1);
    /* text-decoration-color: rgba(63, 135, 123, 1); */
    text-decoration: none;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 10px 20px;
    justify-content: flex-end;
}
.menu li span img {
    transition: transform 0.3s ease-in-out;
}
.menu li span.toggled img {
    transform: rotate(180deg);
}
.menu li span.toggled + .sub-menu {
    max-height: 500px;
}
.menu.close .sub-menu {
    display: none;
}
