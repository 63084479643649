body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    color: #206657
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: 'Outfit-Black';
    src: local('Outfit-Black'), url(./fonts/Outfit/static/Outfit-Black.ttf) format('truetype');
}

@font-face {
    font-family: 'Outfit-Bold';
    src: local('Outfit-Bold'), url(./fonts/Outfit/static/Outfit-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Outfit-ExtraBold';
    src: local('Outfit-ExtraBold'), url(./fonts/Outfit/static/Outfit-ExtraBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Outfit-ExtraLight';
    src: local('Outfit-ExtraLight'), url(./fonts/Outfit/static/Outfit-ExtraLight.ttf) format('truetype');
}

@font-face {
    font-family: 'Outfit-Light';
    src: local('Outfit-Light'), url(./fonts/Outfit/static/Outfit-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Outfit-Medium';
    src: local('Outfit-Medium'), url(./fonts/Outfit/static/Outfit-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Outfit-Regular';
    src: local('Outfit-Regular'), url(./fonts/Outfit/static/Outfit-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Outfit-SemiBold';
    src: local('Outfit-SemiBold'), url(./fonts/Outfit/static/Outfit-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Outfit-Thin';
    src: local('Outfit-Thin'), url(./fonts/Outfit/static/Outfit-Thin.ttf) format('truetype');
}

@font-face {
    font-family: 'PinkSunset-Italic';
    src: url(./fonts/PinkSunset/Web-TT/PinkSunset-Italic.woff) format('woff'), /* Modern Browsers */ url(./fonts/PinkSunset/Web-TT/PinkSunset-Italic.woff2) format('woff2'), /* Modern Browsers */ url(./fonts/PinkSunset/Web-TT/PinkSunset-Italic.ttf) format('truetype'); /* Safari, Android, iOS */
    font-style: italic;
    font-weight: normal;
}

@font-face {
    font-family: 'PinkSunset-Regular';
    src: url(./fonts/PinkSunset/Web-TT/PinkSunset-Regular.woff) format('woff'), /* Modern Browsers */ url(./fonts/PinkSunset/Web-TT/PinkSunset-Regular.woff2) format('woff2'), /* Modern Browsers */ url(./fonts/PinkSunset/Web-TT/PinkSunset-Regular.ttf) format('truetype'); /* Safari, Android, iOS */
    font-style: normal;
    font-weight: normal;
}