.subnews {
    padding-top: 60px;
}

@media (min-width: 1601px) {
    .subnews {
        padding-top: 5%;
    }
}

@media (max-width: 1600px) {
    .subnews {
        padding-top: 10%;
    }
}

@media (max-width: 900px) {
    .subnews {
        padding-top: 20%;
    }
}

@media (max-width: 600px) {
    .subnews {
        padding-top: 30%;
    }
}