.chiffres-cles-container::after {
    content: '';
    position: absolute;
    background-color: #01796F;
    border-radius: 50%;
    width: 0;
    height: 0;
    z-index: -1;
    top: 0;
    right: 0;
    max-width: 1536px;
}

@media (min-width: 320px) {
    .chiffres-cles-container::after {
        width: 300px;
        height: 300px;
        right: -100px;
        top: 100%
    }
}


@media (min-width: 601px) {
    .chiffres-cles-container::after {
        width: 450px;
        height: 450px;
        top: 40%;
        right: -35%;
    }
}

@media (min-width: 751px) {
    .chiffres-cles-container::after {
        width: 450px;
        height: 450px;
        top: 35%;
        right: -20%;
    }
}

@media (min-width: 901px) {
    .chiffres-cles-container::after {
        width: 700px;
        height: 700px;
        top: -10%;
        right: -35%;
    }
}

@media (min-width: 1281px) {
    .chiffres-cles-container::after {
        width: 0;
        height: 0;
        top: 0;
        right: 0;
    }
}
